#json_input{
    text-align: left;
  }
  #json_input-container{
    width: 100% !important;
  }
  #json_input-outer-box{
    width: 100% !important;
  }
  .modal {
    z-index: 2000;
  }

  .modal-content{
    border: 2px solid #9e9e9e52 !important;
  }