.background-div{
  display: flex;
  flex-direction: column;
  
}

.firsthalf{
  background-color: #6690D5;
  height: 50%;
}

.secondhalf{
  background-color: #0047BA;
  height: 50%;
}


.container-div{
  height: 100vh;
  width: 100%;
  position: relative;
  margin-top: 0;
}
.box{
  width: 100%;
  height: 100%;            
  position: absolute;
  top: 0;
  left: 0;
}
.stack-top{
  z-index: 20;
  background: rgb(255, 255, 255);
  height: 600px;
  width: 600px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 15px solid #0047BA;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signin-btn{
  background-color: #0047BA;
    color: white;
    border: none;
    border-radius: 2px;
    width: 350px;
    font-size: 20px;
    height: 50px;
}

.logo{
  position: relative;
    top: 10%;
}

.login-page-img{
  position: relative;
  top: 20%;
}

.signin-title{
  text-align: center;
  position: relative;
  top: 22.5%;
}

.signin-btn{
  position: relative; 
  top: 40%;
}