.custom-file-label{
    height: 53px;
    padding: 16.5px 14px;
}

.custom-file-label::after {
    top: 8px;
    right: 9px;
    border: 1px solid !important;
    margin: 1px;
    border-radius: 4px;
    color: #fff;
    padding: 0.3rem 2rem;
    background-color: #0047BA;
}
.page-name{
    margin: 1rem 0rem;
    
}

