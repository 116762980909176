/* @media (min-width: 1025px) and (max-width: 1650px) {
  body{
    font-size: 12px !important
  }
  
} */

body {
    font-size: 14px ;
    line-height: 20px !important;
    color: #383d38 !important;
    background: #fff;
    -webkit-font-smoothing: antialiased;
    font-weight: 400 !important;
  }
  .pt-30 {
    padding-top: 30px !important;
  }
  .pb-30 {
    padding-bottom: 30px !important;
  }
  .mb {
    margin-bottom: 42px !important;
  }
  .dp-flex {
    display: flex !important;
  }
  .item-center {
    align-items: center !important;
  }
  .space-between {
    justify-content: space-between !important;
  }
  .login-container {
    background-size: cover;
    height: 100vh;
    background-position: right;
    /* background-image: url('/src/assests/images/background-img.png'); */
  }
  .login-box {
    position: absolute;
    color: rgb(208, 96, 48);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    max-width: 400px;
    width: 100%;
    padding: 0 20px;
  }
  .login-box .logo img {
    max-width: 265px;
    width: 100%;
    
  }
  .login-box .input-container {
    margin-bottom: 16px;
  }
  .login-box .input-container input,
  .login-box .input-container button {
    font-size: 18px;
  }
  .login-box h3 {
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    line-height: 51px;
    margin-bottom: 22px;
  }
  .login-box .input-container input {
    width: 100%;
    padding: 10px 15px;
    background: #ffffff;
    border: 1px solid rgba(255, 255, 255);
    color: #2b39d7;
    height: 56px;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
  }
  .login-box .input-container input::placeholder {
    color: #422bd7;
        opacity: 1;
  }
  .login-box .input-container input:focus-visible {
    outline: none;
  }
  .input-container.btn-submit {
    margin-bottom: 20px;
  }
  .login-box .input-container button {
    cursor: pointer;
    color: #fff;
    display: block;
    width: 100%;
    background: #14235e;
    border: 2px solid #14235e;
    transition: border-color 0.35s ease;
    outline: none;
    font-size: 20px;
    font-weight: bold;
    line-height: 33px;
    text-transform: uppercase;
    height: 50px;
  }
  .login-box .input-container button:hover {
    background: transparent;
    border: 2px solid #d79a2b;
    color: #d79a2b;
  }
  .input-container.border-btn {
    margin-bottom: 10px;
  }
  .login-box .input-container.border-btn button {
    background: transparent;
    border: 1px solid #d79a2b;
        color: #d79a2b;
  }
  .login-box .input-container.border-btn button:hover {
    background-color: #d72ba6;
        color: #fff;
  }
  .forgot-password a {
    color: rgb(201, 105, 31);
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    text-decoration: none;
  }
  .forgot-password {
    text-align: right;
    margin-bottom: 17px;
    margin-top: -4px;
  }
  .or {
    text-align: center;
    position: relative;
    padding: 5px 0;
  }
  .or span {
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    width: 100px;
    display: block;
    margin: 0 auto;
    background-color: transparent;
    position: relative;
  }
  .or:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 10%;
    height: 1px;
    background-color: #fff;
    width: 100px;
    z-index: -1;
  }
  .or:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 10%;
    height: 1px;
    background-color: #fff;
    width: 100px;
    z-index: -1;
  }
  .text-btn {
    color: #ffffff;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    display: block;
    font-size: 20px;
    line-height: 30px;
    font-weight: bold;
    text-decoration: none;
    padding: 10px 0;
  }
  
  /*Floting Input start*/
  .did-floating-label-content {
    position: relative;
    margin-bottom: 20px;
  }
  .did-floating-label {
    color: #000000;
    font-size: 20px;
    font-weight: 400;
    position: absolute;
    pointer-events: none;
    left: 15px;
    top: 17px;
    padding: 0 5px;
    background: #fff;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    line-height: 24px;
    margin-bottom: 0;
  }
  
  .did-floating-input {
    font-size: 20px;
    display: block;
    width: 100%;
    height: 56px;
    padding: 0 20px;
    background: #fff;
    color: #000000;
    border: 1px solid #999999;
    border-radius: 0;
    box-sizing: border-box;
    line-height: 24px;
    font-weight: bold;
  }
  
  .did-floating-input1 {
    font-size: 20px;
    display: block;
    width: 100%;
    height: 56px;
    padding: 0 20px;
    background: #fff;
    color: #000000;
    border: 1px solid #999999;
    border-radius: 0;
    box-sizing: border-box;
    line-height: 24px;
    font-weight: bold;
  }
  .did-floating-input:focus {
    outline: none;
  }
  .did-floating-input:focus ~ .did-floating-label {
    top: -10px;
    font-size: 16px;
  }
  .did-floating-input:not(:placeholder-shown) ~ .did-floating-label {
    top: -10px;
    font-size: 16px;
  }
  .did-floating-label-content textarea {
     padding: 20px;
  }
  .did-error-input .did-floating-input,
  .did-error-input .did-floating-select {
    border: 2px solid #9d3b3b;
    color: #9d3b3b;
  }
  .did-error-input .did-floating-label {
    font-weight: 600;
    color: #9d3b3b;
  }
  .input-group {
    display: flex;
  }
  .input-group .did-floating-input {
    border-radius: 0 4px 4px 0;
    border-left: 0;
    padding-left: 0;
  }
  .input-group-append {
    display: flex;
    align-items: center; /*   margin-left:-1px; */
  }
  /*Floting Input End*/
  
  /*Create an Account*/
  .login-container.signup-container {
    height: auto;
  }
  .signup-inner {
    padding: 150px 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .signup-inner .login-box.signup-box {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    max-width: 480px;
    width: 100%;
  }
  .signup-inner .login-box.signup-box .logo {
    margin-bottom: 59px !important;
  }
  .login-box.signup-box .logo img {
    max-width: 200px;
    width: 100%;
  }
  .login-box.card {
    padding: 50px;
  }
  .login-box.card h6 {
    color: #000000;
    margin: 0 auto 33px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    line-height: 33px;
  }
  .login-box.card span {
    color: #000000;
    margin: 0 auto 30px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    line-height: 24px;
  }
  .login-box.card p {
    color: #000000;
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    margin-bottom: 30px;
  }
  .login-box.signup-box.card p a {
    text-decoration: underline;
    font-weight: bold;
    color: #000000;
  }
  .login-box.signup-box .input-container input {
    border: 1px solid #bdbdbd;
    font-weight: 400;
    color: #000000;
  }
  .login-box.signup-box .input-container input::placeholder {
    color: #999999;
    opacity: 1;
  }
  .login-box.signup-box .input-container input:focus-visible {
    outline: none;
  }
  .login-box.signup-box .requirement {
    border-bottom: 1px solid #999999;
  }
  .login-box.signup-box .requirement span {
    font-size: 20px;
    color: #000000;
    line-height: 24px;
    font-weight: 400;
  }
  .login-box.signup-box .requirement ul {
    padding-left: 20px;
    margin-bottom: 0;
  }
  .login-box.signup-box .requirement ul li {
    font-size: 20px;
    color: #000000;
    line-height: 24px;
    font-weight: 400;
  }
  
  .login-box.signup-box.card .input-container.border-btn button,
  .login-box.signup-box.card .input-container button {
    padding: 11px;
    box-shadow: 0px 3px 6px #00000029;
  }
  .login-box.card p a {
    color: #343434;
  }
  .privacy-terms a {
    font-weight: 400 !important;
  }
  .signup-inner .login-box.signup-box .input-container.border-btn {
    margin-bottom: 0;
  }
  .signup-inner .login-box.signup-box .input-container {
    margin-bottom: 0;
  }
  
  @media (max-width: 576px) {
    
    .dp-flex {
      display: block !important;
    }
    .login-box {
      padding: 0 30px;
    }
    .login-box .input-container button {
      font-size: 17px;
    }
    .or:before {
      left: 6%;
      width: 85px;
    }
    .or:after {
      right: 6%;
      width: 85px;
    }
    .login-box.card {
      padding: 20px;
    }
    .signup-inner .login-box.signup-box {
      max-width: 90%;
    }
    .signup-inner .login-box.signup-box .input-container.border-btn {
      margin-bottom: 20px;
    }
  }
  

  .backtosignIn{
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #fff;
    padding-bottom: 10px;
    font-size: 17px;
  }

  .backtosignIn a{
    text-decoration: none;

  }


  /* Place over */

      /* .login-box{
        color: rgb(133, 208, 48);
      }

      .login-box .input-container input{
        border: 1px solid rgba(255, 255, 255);
        color: #bb22af;
      }

      .login-box .input-container input::placeholder {
        color: #2bd739;
        opacity: 1;
      }

      .login-box .input-container button {
        background: #145e3c;
        border: 2px solid #145e3c;
      }


      .login-box .input-container button:hover {
        border: 2px solid #2ba3d7;
        color: #8a1108;
      }

      .login-box .input-container.border-btn button {
        border: 1px solid #2bd78c;
        color: #2bd78c;
      }

      .login-box .input-container.border-btn button:hover {
        background-color: #146c3b;
        color: #fff;
      }

      .forgot-password a {
        color: rgb(31, 136, 201);
      }
      
       .login-container {
        background-image: url(http://java9.indianic.com/pepsico_apps/consumer1/assets/img/background-img.png);
      }  */

      /* .login-container {
        background-image: url(http://java9.indianic.com/pepsico_apps/consumer1/assets/img/background-img1.png);
      }  */


      /* Multi Factor Page*/

      
.signup-inner .login-box.signup-box.multi-factor-main {
  max-width: 540px;
  margin-top: 100px;
}
.signup-inner .login-box.multi-factor-main.card {
  padding: 50px 30px;

}

.login-box.card.multi-factor-main h6 {
  color: #273f9d;
}

.radio-group {
  margin-bottom: 25px;
}
.radio-group .radio-inner .img-circle {
  padding: 5px;
  border: 1px solid #cccccc;
  background-color: #def2ff;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.radio-group .radio-inner .img-circle img {
  width: 30px;
}

.radio-group .radio-inner .radio-title {
  color: #000;
  font-size: 20px;
  font-weight: 900;
}

.radio-group .radio-inner .radio-desc p {
  font-size: 16px;
  margin-bottom: 0;
}
.radio-group .custom-control-label::after {
  top: 0.75rem;
}
.radio-group .custom-control-label::before {
  top: 0.75rem;
}

.radio-group .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #273f9d;
  background-color: #273f9d;
}
.radio-group .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgb(39 63 157 / 25%);
}

.multi-factor-main button.btn-purple {
  background-color: #273f9d;
  border: 2px solid #273f9d;
  color: #ffffff;
  max-width: 100%;
  width: auto;
  display: inline-block;
}
.multi-factor-main button.btn-purple:hover {
  background-color: #ffffff;
  color: #273f9d;
  border-color: #273f9d;
}
.login-box.card.multi-factor-main .privacy-terms {
  margin-bottom: 0;
}

@media (max-width: 576px) {
  .dp-flex {
    display: block !important;
  }
  .login-container.signup-container {
    height: auto;
  }
  
  .login-box {
    padding: 0 30px;
  }
  .login-box .input-container button {
    font-size: 15px;
  }
  .or:before {
    left: 6%;
    width: 85px;
  }
  .or:after {
    right: 6%;
    width: 85px;
  }
  .login-box.card {
    padding: 20px;
  }
  .signup-inner .login-box.signup-box {
    max-width: 90%;
  }
  .signup-inner .login-box.signup-box .input-container.border-btn {
    margin-bottom: 20px;
  }
  .login-box .logo img {
    max-width: 200px;
    margin-top: 20px;
  }
  
  .login-box h3 {
    font-size: 30px;
  }
  .did-floating-input1 {
    font-size: 15px;
    display: block;
    width: 100%;
    height: 35px;
    padding: 0 10px;
    background: #fff;
    color: #000000;
    border: 1px solid #999999;
    border-radius: 0;
    box-sizing: border-box;
    line-height: 24px;
    font-weight: bold;
  }

  .signup-inner .login-box.signup-box.multi-factor-main {
    max-width: 380px;
    margin-left: 15px;
    margin-right: 15px; 
    
  }
  .signup-inner .login-box.multi-factor-main.card {
    padding: 50px 30px;
  }
  .input-container button{
     font-size: 10px;
  }
}

.PasswordIcon{
  position: absolute;
  cursor: pointer;
 
}

.PasswordIcon span{
   font-size: 15px;
   font-weight: bold;
}

@media (max-width: 400px) {
  .login-box .input-container button {
    font-size: 15px;
  }
}

@media (max-width: 375px) {
  .login-box .input-container button {
    font-size: 12px;
  }
}

