body {
    font-size: 1em;
}

@font-face {
    font-family: 'Cocogoose Pro SemiLight Trial';
    src: url('../../fonts/Cocogoose\ Pro\ SemiLight\ Trial.eot');
    src: url('../../fonts/Cocogoose Pro SemiLight Trial.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/Cocogoose\ Pro\ SemiLight\ Trial.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'CocogoosePro-SemiLight';
    src: url('../../fonts/CocogoosePro-SemiLight.svg#CocogoosePro-SemiLight') format('svg'),
         url('../../fonts/CocogoosePro-SemiLight.ttf') format('truetype'),
         url('../../fonts/CocogoosePro-SemiLight.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }


.appconfigform {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    margin: none;
    border-radius: 4px;
    padding: 20px;
    overflow: hidden;
}

#input-color {
    -webkit-appearance: none;
    border: none;
    width: 55px;
    height: 55px;
    padding: 5px;
}

#input-color::-webkit-color-swatch-wrapper {
    padding: 0;
}

#input-color::-webkit-color-swatch {
    border: none;
    border-radius: 10%;
}

.fontFamily {
    width: 20rem;
}

.container {
    margin-top: 30px;
    overflow: hidden;
    width: 90%;
}

.form-control {
    padding: 0.2rem 0.5rem;
    border: 1px solid #ccc;
}

.input-group>.form-control {
    width: 80%;
}

.input-group>.form-control:not(:last-child) {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.panel {
    background-color: #fff;
    border: 1px solid transparent;
}

.panel-group .panel {
    margin-bottom: 5px;
    border-radius: 4px;
    padding: 10px;
}

.panel-default {
    border-color: #ddd;
}

.consumer-app-btn {
    display: flex;
    align-items: flex-end;
}

table td {
    color: #98a8b4;
}

#tb2 {
    counter-reset: serial-number; 
}

.carousel-root{
    height: 100vh;
}

.carousel .slide{
    height: 100vh !important;
    overflow-y: auto !important;
    text-align: left !important;
}
.carousel .control-next.control-arrow { 
    right: 17px !important;
}
.carousel.carousel-slider .control-arrow, .carousel .control-arrow {
    background-color: #000000;
}
.css-1chwz6k-MuiPaper-root-MuiDialog-paper {
    overflow: hidden !important;
}
.css-f6vpyr {
    overflow: hidden !important;
}

/* #tb2 td:first-child:before {
    counter-increment: serial-number;  
    content: counter(serial-number);  
} */

#input-color{
    background-color: white;
}


.tableAlignment{
    text-align: center;
}

.boldcolor{
    display: inline-block;
    color: #70AD47;
    text-transform: lowercase;
    font-size: 14px;
    font-family: 'Courier New', Courier, monospace;
    margin: 0;
}

.mainTable{
    table-layout: fixed;
    width: 100%;
}
.GenerateURLTable {
    table-layout: fixed;
    width: 97%;
    overflow: hidden;
    font-weight: 0;
}

.RowName{
    width: 10%;
}


.GenerateURLTable tr td:last-child{
    font-weight: normal;
    font-family: 'Courier New', Courier, monospace;
}

.RowNameMain{
    width: 3%;
}
.css-rqglhn-MuiTable-root{
    table-layout: fixed;
    width: 100%;
}
.css-1owb465{
    table-layout: fixed;
    width: 100%;
}

table td {
    word-wrap: break-word;         /* All browsers since IE 5.5+ */
    overflow-wrap: break-word;    /* Renamed property in CSS3 draft spec */
}

.CustomEmailPopUp{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Cocogoose Pro SemiLight Trial';
}

.btn-secondary{
    border-radius: 6px;
}

.table-thead-th-owner{
    width: max-content;
}


table > thead> tr > th > i{
    color: #98a8b4;
}

table > thead> tr > th > div> i{
    color: #98a8b4;
}

.image-preview{
    width: 100px;
    margin-top: -5px;
}

/* .image-preview:hover{
    width: 500px;
    height: auto;
} */

.ReactModal__Overlay{
    z-index: 100;
} 

.ReactModal__Content{
    width: 100%;
}

.close {
    position: absolute;
    right: 20px;
    top: 10px;
    width: 50px;
    height: 50px;
    opacity: 0.3;
    border: 2px solid rgb(0, 0, 0);
    border-radius: 50%;
  }
  .close:hover {
    opacity: 1;
  }
  .close:before, .close:after {
    position: absolute;
    left: 21px;
    top: 7px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: rgb(0, 0, 0);
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }

  .MuiDialogActions-root{
      height: 70px;
  }

.fileUpload-browse{
    padding: 16.5px 14px;
    top: 8px;
    right: 9px;
    border: 1px solid !important;
    margin: 1px;
    border-radius: 4px;
    color: #fff;
    padding: 0.3rem 2rem;
    background-color: #0047BA;
    font-size: 15px;
}

.fileUpload-browse::after {
    top: 8px;
    right: 9px;
    border: 1px solid !important;
    margin: 1px;
    border-radius: 4px;
    color: #fff;
    padding: 0.3rem 2rem;
    background-color: #0047BA;
}

.card-title{
    font-family: "CocogoosePro-SemiLight", sans-serif;
}

.buttons-sticky{
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.MuiButton-outlined{
    width: 70vh;
    height: 43px;
    padding-left: 0;
}

.card-header{
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    margin: 10px;
    box-shadow:  1px 2px 5px 1px rgb(52 40 104 / 8%)
}

/* .card-header:active{
    background-color: #0047BA;
} */

/* .collapsed{
    background-color: #d5e1f7 !important;
} */

.MuiFormControl-root{
    width: 100%;
}

.MuiSvgIcon-root{
    height: 35px;
    width: 35px;
    /* position: relative;
    left: 30%; */
}

.closeButton:hover{
    cursor: pointer;
}

.MuiPaper-root{
    width: -webkit-fill-available;
}

.previewBtns{
    display: flex;
    /* justify-content: end; */
    padding-top: 10px;
}


.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
    font-family: 'CocogoosePro-SemiLight' !important;
}

.css-qiwgdb.MuiSelect-select {
    height: 0 !important;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select{
    height: 0 !important;
}

.languages {
    position: relative;
    top: 0px;
}

.searchWrapper {
    min-height: 50px !important;
    border: 1px solid #cbcbcb !important;
}

.chip{
    background-color: #E8E8E8 !important;
    color: #000000 !important;
}

/* .css-i4bv87-MuiSvgIcon-root{
    height: 30px !important;
    width: 30px !important;
    position: relative;
    left: -5px;
    color: #000000;
}

.profileName{
    position: relative;
    left: -50px;
    color: #000000;
}

.profileIcon{
    position: relative;
    left: -50px;
    color: #000000;
} */

.span2{
    padding: 0;
}

.MuiSvgIcon-root{
    height: 30px !important;
    width: 30px !important;
}

.logoutBtn{
    color: black;
    position: relative;
    left: -10%;
    height: 30px;
    width: 30px;
    cursor: pointer;
}

.profileName{
    color: black;
    position: relative;
    left: -20%;
    font-size: initial;
}

.profileIcon{
    color: black;
    position: relative;
    left: -25%;
}

.sticky-btn{
    position: sticky;
    bottom: 0;
}

.groupID, .oktaAppID{
    position: relative;
    top: 0px;
}

.css-1s2u09g-control{
    height: 53px;
    border-radius: 4px;
}

.addClass{
    color: #0047BA;
    display: flex;
    font-family: inherit;
    font-weight: 600;
    font-size: 16px;
    border: 2px solid #0047BA;
    border-radius: 5px;
    height: 45px;
    justify-content: center;
    align-items: center;
    padding: 10px;
    position: relative;
    width: fit-content;
}

.addLink{
    color: rgb(0, 71, 186);
    font-size: large;
    position: relative;
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.page-not-found{
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    position: relative;
    margin-top: 0;
    background-color: #E5ECF8;
}

/* .page-not-found-content{
    height: 100%;
} */
.authModuleRestrict{
    pointer-events: none !important;
}

.logo-small-transparentBg{
    height: 60px;
}

.container-404{
    color: #003282;
    font-size: 100px;
    height: 100px;
}

.pnf-message{
    font-family: 'Cocogoose Pro SemiLight Trial';
    font-size: 22px;
}

.page-not-found-logo{
    position: relative;
    top: 20%;
}

.page-not-found-content{
    align-items: center;
    flex-direction: column;
    position: relative;
    top: 40%;
}


.HtmlTable{
    table-layout: fixed;
    width: 100%;
}


.HtmlTable th{
    padding:5px 10px !important;
    border: 1.5px solid white !important;
    font-size: 2rem !important;
}

.HtmlTable tr:first-child th:first-child{
    border-top-left-radius: 10px;
}
.HtmlTable tr:first-child th:last-child{
    border-top-right-radius: 10px;
}


.table-striped>tbody>tr:nth-child(2n+2)>td{
    background-color: #eaf0ff;

}
.table-striped>tbody>tr:nth-child(2n+1)>td{
    background-color: white;

}
.table-striped tr th {
   background-color: #4F81BD;
   color: white; 
   font-size: 1rem !important;
 }


 .heading{
    text-align: left;
    margin-bottom: 1em;
 }

 .menuIcon{
    color: #004FBA;
    cursor: pointer;
    transition: all 0.5s ease;
 }