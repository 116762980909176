.MuiCard-root{
    margin: 1% !important;
    background:#ffff !important;
    box-shadow: 1px 0px 3px 5px rgba(131, 129, 129, 0.18) !important;
    border-radius:25px !important;
    color: black !important;
}
.MuiTypography-root{
    color:  black;
}

/* .custom-datepicker{
    border: 1px solid #e5e5ea;
    border-radius: 6px;
    padding: 10px;
    font-size: 15px;
    width: 100%;
    text-align: left;
    height: 30px;
    margin-bottom: 10px;
    color: #1675e0;
    transition: all .15s ease-in-out;
  }
  .custom-datepicker:hover{
    border: 1px solid #3498ff;
    cursor: pointer;
    
  }



  .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
    left: -100px !important;
  }
  .react-datepicker-popper[data-placement^=bottom]{
    padding-top: 0px !important;
  } */

 /* Add these styles to your existing CSS */

/* Label styles */
.label {
  font-size: 1rem;
  margin-bottom: 10px;
  color: #000000;
  font-weight: bold;
}

.gridStyle{
  display:'flex';
  align-items:center;
  justify-content:center;
  gap:5%;
}

/* Date picker styles */
.custom-datepicker {
  border: 1px solid #e5e5ea;
  border-radius: 6px;
  padding: 10px;
  font-size: 15px;
  width: 100%;
  text-align: left;
  height: 35px;
  color: #1675e0;
  transition: all 0.15s ease-in-out;
  margin-bottom: 10px;
}

.custom-datepicker:hover {
  border: 1px solid #3498ff;
  cursor: pointer;
}

.enableblurback {
  background-color: #f3f3f3; /* Adjust the background color for the enableblurback state */
}

/* CheckPicker styles */
.CheckPicker {
  width: 50%;
  display: block;
  margin-bottom: 10px;
}

/* Optionally, you can adjust the styles for the CheckPicker dropdown menu */
.CheckPicker .rs-check-item {
  padding: 8px 10px;
}

.CheckPicker .rs-check-item-check {
  border-radius: 4px;
}

.CheckPicker .rs-check-item:hover {
  background-color: #f3f3f3;
}


  .enableblurback{
    box-shadow: 0 0 0 3px rgba(52,152,255,.25) !important;
  }