.unauthorised-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #E5ECF8;
  }
  
  .unauthorised-card {
    border: none;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    width: 30em;
    max-width: 80%;
    height: 15em;
    background-color: #ffffff;
    border-radius: 8px;
  }
  
  .unauthorised-content {
    padding: 2em;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .unauthorised-text h3 {
    text-align: center;
    color: #e44d26;
    font-size: 1.5em;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .unauthorised-text h3:hover {
    color: #007bff;
  }
  
  .unauthorised-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5em;
  }
  
  .unauthorised-buttons .btn {
    padding: 0.8em 1.5em;
    color: #ffffff;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    font-size: 1em;
  }
  
  .unauthorised-buttons  .btn-primary {
    background-color: #007bff;
  }
  
  .unauthorised-buttons  .btn-primary:hover {
    background-color: #0056b3;
  }
  
  .unauthorised-buttons  .btn-danger {
    background-color: #dc3545;
  }
  
  .unauthorised-buttons  .btn-danger:hover {
    background-color: #c82333;
  }
  